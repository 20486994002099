import { Button } from "antd";
import { useRecordContext } from "ra-core";
import { useNotify } from "react-admin";
import { AuthenticatedApi } from "../../utils/AuthenticatedApi";
import { handleError } from "../../utils/utilities";

const ResendVerificationEmailButton = () => {
  const record = useRecordContext();
  const notify = useNotify();

  const handleButtonClick = (event) => {
    event.stopPropagation();
    console.log("Button clicked for record:", record);

    AuthenticatedApi.get(
      `/api/user_accounts/${record.id}/resend_verification_email`,
      {
        params: { internal_request: true },
      }
    )
      .then((response) => {
        console.log(response.data);
        notify("Verification email sent successfully.", "success");
      })
      .catch((error) => {
        console.error("Error resending verification email: ", error);
        const errorMessage = handleError(error);
        notify(errorMessage, "error");
      });
  };
  if (record.uid === null) {
    return (
      <Button onClick={handleButtonClick}>Resend Verification Email</Button>
    );
  } else {
    return null;
  }
};

export default ResendVerificationEmailButton;
