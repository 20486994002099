import React, { useState, useContext } from "react";
import { Form, Button, Typography, Upload, notification, Input } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import { handleError, formatMessage } from "../utils/utilities";
import ConfigContext from "../context/ConfigContext";

const { Title } = Typography;
const { TextArea } = Input;
const TaskGenericSleepTask = (props) => {
  const [fileList, setFileList] = useState(props.fileList);
  const [waiting] = useState(props.waiting);
  const [enabledSubmit, setEnabledSubmit] = useState(props.enabledSubmit);
  const [tasks] = useState(props.tasks);
  const setTasks = props.setTasks;
  const handleCancel = props.handleCancel;
  const setWaiting = props.setWaiting;
  const taskType = props.taskType;
  const taskName = props.taskName;
  const config = useContext(ConfigContext);

  const Instructions = () => (
    <p>
      <b>Acceptable file formats:</b> PDF, JPG/JPEG, PNG, TIFF.
      <br />
      Please ensure the document contains medical information related to a
      single patient only.
    </p>
  );

  const handleUpload = ({ fileList }) => {
    setFileList(fileList);
    setEnabledSubmit(fileList.length > 0);
  };

  const clear = () => {
    setFileList([]);
    setEnabledSubmit(false);
    setWaiting(false);
    handleCancel();
  };

  const handleSubmit = async (values) => {
    setWaiting(true);
    let data_items = [
      {
        name: "Task Requirements",
        tag: "request-spec",
        data_type: "json",
        data: {
          patient_hcpcs: (values["patient_hcpcs"] || "").trim(),
          patient_icd10: (values["patient_icd10"] || "").trim(),
        },
      },
    ];

    fileList.forEach((file, index) => {
      data_items.push({
        tag: "upload",
        data_type: "file",
        data: index,
        file_name: file.originFileObj.name,
      });
    });

    let createTask = {
      task_type: taskType,
      data_items: data_items,
    };

    let formData = new FormData();
    formData.append("create_task", JSON.stringify(createTask));
    console.log(fileList);
    if (fileList.length > 0) {
      fileList.forEach((file) => {
        formData.append("uploads", file.originFileObj);
      });
    }
    console.log(formData);
    AuthenticatedApi.post("/api/v2/task/create", formData)
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          setTasks([response.data, ...tasks]);
        }
        clear();
      })
      .catch((error) => {
        const errorMessage = handleError(error);
        notification.error({ message: errorMessage });
        clear();
      });
  };

  return (
    <div>
      <Title className="gpt-form-title">{taskName}</Title>
      <Instructions />
      <Form
        className="gpt-form-text"
        onFinish={handleSubmit}
        layout={"vertical"}
      >
        <Form.Item name="file_upload">
          <Upload
            beforeUpload={(file) => {
              const supportedExtensions = ["pdf", "jpg", "jpeg", "png", "tif"];
              const lowerCaseFileName = file.name.toLowerCase();
              const isSupportedFormat =
                [
                  "application/pdf",
                  "image/jpeg",
                  "image/png",
                  "image/tiff",
                ].includes(file.type) ||
                supportedExtensions.includes(
                  file.name.slice(file.name.lastIndexOf(".") + 1).toLowerCase()
                ) ||
                supportedExtensions.some((ext) =>
                  lowerCaseFileName.includes(ext)
                );

              if (!isSupportedFormat) {
                notification.error({
                  message: formatMessage(config.messages.tasks.invalid_file, {
                    file_list: "PDF, JPG/JPEG, PNG, and TIFF",
                  }),
                });
              }
              return false;
            }}
            multiple={true}
            onChange={(info) => {
              handleUpload(info);
            }}
            fileList={fileList}
            itemRender={(originNode) => {
              // Remove any tooltips
              return React.cloneElement(originNode, { title: "" });
            }}
          >
            <Button icon={<UploadOutlined />}>
              Please upload a patient referral
            </Button>
          </Upload>
        </Form.Item>
        {(taskType === "mwc_validation" ||
          taskType === "mwc_funding" ||
          taskType === "pwc_validation" ||
          taskType === "pwc_funding") && (
          <div>
            <Form.Item
              name="patient_hcpcs"
              label="Optionally, enter HCPCS codes for this patient's order"
            >
              <TextArea className="gpt-form-text-area" rows={5} />
            </Form.Item>
            <Form.Item
              name="patient_icd10"
              label="Optionally, enter ICD-10 codes for this patient's order"
            >
              <TextArea className="gpt-form-text-area" rows={5} />
            </Form.Item>
          </div>
        )}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={waiting}
            disabled={!enabledSubmit}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default TaskGenericSleepTask;
