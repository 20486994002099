import { useState } from "react";
import { Form, Button, Typography, Input, Checkbox, notification } from "antd";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import { handleError } from "../utils/utilities";

const { Title } = Typography;
const S3TaskVoiceAgent = (props) => {
  const [fileList, setFileList] = useState([]);
  const [waiting] = useState(props.waiting);
  const [enabledSubmit, setEnabledSubmit] = useState(true);
  const [tasks] = useState(props.tasks);
  const setTasks = props.setTasks;
  const handleCancel = props.handleCancel;
  const setWaiting = props.setWaiting;

  const clear = () => {
    setFileList([]);
    setEnabledSubmit(true);
    setWaiting(false);
    handleCancel();
  };

  const handleSubmit = async (values) => {
    setWaiting(true);
    let data_items = [];
    data_items = [
      {
        name: "Task Requirements",
        tag: "request-spec",
        data_type: "json",
        data: {
          patient_id: fetchDictValues(values, "s3_patient_id"),
          patient_first_name: fetchDictValues(values, "s3_patient_first_name"),
          patient_last_name: fetchDictValues(values, "s3_patient_last_name"),
          patient_dob: fetchDictValues(values, "s3_patient_dob"),
          questions_to_ask: {
            inpatient_care: fetchDictValues(
              values,
              "s3_questions_to_ask_inpatient_care"
            ),
            wear_and_tear: fetchDictValues(
              values,
              "s3_questions_to_ask_wear_and_tear"
            ),
            insurance_change: fetchDictValues(
              values,
              "s3_questions_to_ask_insurance_change"
            ),
            usage: fetchDictValues(values, "s3_questions_to_ask_usage"),
            last_seen_physician: fetchDictValues(
              values,
              "s3_questions_to_ask_last_seen_physician"
            ),
          },
          equipment: [
            {
              product: "seals",
              eligible: fetchDictValues(values, "s3_equipment_seals_eligible"),
              quantity: fetchDictValues(values, "s3_equipment_seals_quantity"),
            },
            {
              product: "pillows",
              eligible: fetchDictValues(
                values,
                "s3_equipment_pillows_eligible"
              ),
              quantity: fetchDictValues(
                values,
                "s3_equipment_pillows_quantity"
              ),
            },
            {
              product: "cushions",
              eligible: fetchDictValues(
                values,
                "s3_equipment_cushions_eligible"
              ),
              quantity: fetchDictValues(
                values,
                "s3_equipment_cushions_quantity"
              ),
            },
            {
              product: "nondisposable_filter",
              eligible: fetchDictValues(
                values,
                "s3_equipment_nondisposable_filter_eligible"
              ),
              quantity: fetchDictValues(
                values,
                "s3_equipment_nondisposable_filter_quantity"
              ),
            },
            {
              product: "disposable_filter",
              eligible: fetchDictValues(
                values,
                "s3_equipment_disposable_filter_eligible"
              ),
              quantity: fetchDictValues(
                values,
                "s3_equipment_disposable_filter_quantity"
              ),
            },
            {
              product: "water_chamber",
              eligible: fetchDictValues(
                values,
                "s3_equipment_water_chamber_eligible"
              ),
            },
            {
              product: "chinstrap",
              eligible: fetchDictValues(
                values,
                "s3_equipment_chinstrap_eligible"
              ),
            },
            {
              product: "standard_tubing",
              eligible: fetchDictValues(
                values,
                "s3_equipment_standard_tubing_eligible"
              ),
            },
            {
              product: "heated_tubing",
              eligible: fetchDictValues(
                values,
                "s3_equipment_heated_tubing_eligible"
              ),
            },
            {
              product: "headgear",
              eligible: fetchDictValues(
                values,
                "s3_equipment_headgear_eligible"
              ),
            },
            {
              product: "mask",
              eligible: fetchDictValues(values, "s3_equipment_mask_eligible"),
              product_name: fetchDictValues(
                values,
                "s3_equipment_mask_product"
              ),
              location: fetchDictValues(values, "s3_equipment_mask_location"),
              size: fetchDictValues(values, "s3_equipment_mask_size"),
            },
          ],
          insurances: [
            {
              insurance_company_name: fetchDictValues(
                values,
                "s3_insurance_insurance_company_name"
              ),
              policy_number: fetchDictValues(
                values,
                "s3_insurance_policy_number"
              ),
              group_number: fetchDictValues(
                values,
                "s3_insurance_group_number"
              ),
              policy_start_date: null,
              policy_end_date: null,
              guarantor_relation: fetchDictValues(
                values,
                "s3_insurance_guarantor_relation"
              ),
              primary_order: fetchDictValues(
                values,
                "s3_insurance_primary_order"
              ),
            },
          ],
          contact: {
            email: fetchDictValues(values, "s3_patient_email"),
            phone: fetchDictValues(values, "s3_patient_phone"),
            street_address: fetchDictValues(
              values,
              "s3_patient_street_address"
            ),
            street_address2: fetchDictValues(
              values,
              "s3_patient_street_address2"
            ),
            city: fetchDictValues(values, "s3_patient_city"),
            state: fetchDictValues(values, "s3_patient_state"),
            zip: fetchDictValues(values, "s3_patient_zip"),
          },
          task_custom_fields: {
            expert_number: fetchDictValues(values, "s3_expert_number"),
            objectives: fetchDictValues(values, "objectives"),
          },
          from_front_end: true,
        },
      },
    ];
    fileList.forEach((file, index) => {
      data_items.push({
        tag: "upload",
        data_type: "file",
        data: index,
        file_name: file.originFileObj.name,
      });
    });

    let createTask = {
      task_type: "voice_agent",
      data_items: data_items,
    };

    let formData = new FormData();
    formData.append("create_task", JSON.stringify(createTask));
    console.log(fileList);
    if (fileList.length > 0) {
      fileList.forEach((file) => {
        formData.append("uploads", file.originFileObj);
      });
    }
    AuthenticatedApi.post("/api/v2/task/create", formData)
      .then((response) => {
        if (response.data) {
          setTasks([response.data, ...tasks]);
        }
        clear();
      })
      .catch((error) => {
        const errorMessage = handleError(error);
        notification.error({ message: errorMessage });
        clear();
      });
  };

  const fetchDictValues = (values, key) => {
    return values[key] !== undefined ? values[key] : s3Values[key];
  };

  const s3Values = {
    s3_patient_first_name: "Taylor",
    s3_patient_last_name: "Swift",
    s3_patient_id: "ABC31415926",
    s3_patient_dob: "01-01-2000",
    s3_patient_email: "troubletroubletrouble@aol.com",
    s3_patient_street_address: "123 Main St",
    s3_patient_street_address2: "",
    s3_patient_city: "Anytown",
    s3_patient_state: "CA",
    s3_patient_zip: "12345",
    s3_caregivers_authorized: true,
    s3_patient_phone: "+919029770085",
    s3_expert_number: "+919029770085",
    s3_questions_to_ask_inpatient_care: true,
    s3_questions_to_ask_wear_and_tear: true,
    s3_questions_to_ask_insurance_change: true,
    s3_questions_to_ask_usage: true,
    s3_questions_to_ask_last_seen_physician: true,
    s3_physician_name: "Doogie Howser",
    s3_physician_address_street_address: "1600 Pennsylvania Ave NW",
    s3_physician_address_street_address2: "",
    s3_physician_address_city: "Washington",
    s3_physician_address_state: "DC",
    s3_physician_address_zip: "20500",
    s3_physician_phone: "",
    s3_physician_fax: "",
    s3_physician_npi: "",
    s3_insurance_insurance_company_name: "Blue Cross Blue Shield",
    s3_insurance_policy_number: "",
    s3_insurance_group_number: "",
    s3_insurance_policy_start_date: "",
    s3_insurance_policy_end_date: "",
    s3_insurance_guarantor_relation: "",
    s3_insurance_primary_order: "1",
    s3_equipment_seals_eligible: true,
    s3_equipment_seals_quantity: "90 day supply",
    s3_equipment_cushions_eligible: false,
    s3_equipment_cushions_quantity: "",
    s3_equipment_pillows_eligible: false,
    s3_equipment_pillows_quantity: "",
    s3_equipment_disposable_filter_eligible: true,
    s3_equipment_disposable_filter_quantity: "90 day supply",
    s3_equipment_nondisposable_filter_eligible: false,
    s3_equipment_nondisposable_filter_quantity: "",
    s3_equipment_water_chamber_eligible: false,
    s3_equipment_chinstrap_eligible: false,
    s3_equipment_standard_tubing_eligible: true,
    s3_equipment_heated_tubing_eligible: false,
    s3_equipment_mask_eligible: false,
    s3_equipment_mask_product: "Airfit F30i",
    s3_equipment_mask_location: "Under the nose",
    s3_equipment_mask_size: "Medium",
    s3_equipment_headgear_eligible: false,
    objectives: "NA",
    firstName: "Foo",
    lastName: "Bar",
    dob: "1/1/2000",
    payorPhone: "+919029770085",
    doctor_name: "John Doe",
    expertNumber: "+919029770085",
    insuranceId: "B1223314",
    groupNumber: "92341",
    cptCodes: "95810 8/1/2023",
    npi: "",
    facility: "",
    providerAddress: "",
    address: "",
    email: "",
    enableSplitting: false,
  };

  return (
    <div>
      <Title className="gpt-form-title">AI Caller</Title>
      <p>Verify patient benefits by phone referencing their medical records.</p>
      <Form
        className="gpt-form-text"
        onFinish={handleSubmit}
        loading={waiting ? true : undefined}
        layout={"vertical"}
        initialValues={{
          ...s3Values,
        }}
      >
        <Form.Item
          name="s3_patient_first_name"
          label="First Name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_patient_last_name"
          label="Last Name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_patient_dob"
          label="Date of Birth"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_patient_id"
          label="Patient ID"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_patient_email"
          label="Email"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_patient_phone"
          label="Phone"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_expert_number"
          label="Expert Phone"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_caregivers_authorized"
          label="Caregivers Authorized"
          valuePropName="checked"
          rules={[{ required: true }]}
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="s3_questions_to_ask_inpatient_care"
          label="Inpatient Care"
          valuePropName="checked"
          rules={[{ required: true }]}
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="s3_questions_to_ask_wear_and_tear"
          label="Wear and Tear"
          valuePropName="checked"
          rules={[{ required: true }]}
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="s3_questions_to_ask_insurance_change"
          label="Insurance Change"
          valuePropName="checked"
          rules={[{ required: true }]}
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="s3_questions_to_ask_usage"
          label="Usage"
          valuePropName="checked"
          rules={[{ required: true }]}
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="s3_questions_to_ask_last_seen_physician"
          label="Last Seen Physician"
          valuePropName="checked"
          rules={[{ required: true }]}
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="s3_physician_name"
          label="Physician Name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_physician_address_street_address"
          label="Physician Street Address"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_physician_address_street_address2"
          label="Physician Street Address 2"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_physician_address_city"
          label="Physician City"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_physician_address_state"
          label="Physician State"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_physician_address_zip"
          label="Physician Zip"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_physician_phone"
          label="Physician Phone"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_physician_fax"
          label="Physician Fax"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_physician_npi"
          label="Physician NPI"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_insurance_insurance_company_name"
          label="Insurance Company Name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_insurance_policy_number"
          label="Insurance Policy Number"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_insurance_group_number"
          label="Insurance Group Number"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_insurance_policy_start_date"
          label="Insurance Policy Start Date"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_insurance_policy_end_date"
          label="Insurance Policy End Date"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_insurance_guarantor_relation"
          label="Guarantor Relation"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_insurance_primary_order"
          label="Primary Order"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_patient_street_address"
          label="Street Address"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_patient_street_address2"
          label="Street Address 2"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_patient_city"
          label="City"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_patient_state"
          label="State"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_patient_zip"
          label="Zip"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_equipment_seals_eligible"
          label="Seals: Eligible"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item name="s3_equipment_seals_quantity" label="Seals: Quantity">
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_equipment_pillows_eligible"
          label="Pillows: Eligible"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="s3_equipment_pillows_quantity"
          label="Pillows: Quantity"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_equipment_cushions_eligible"
          label="Cushions: Eligible"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="s3_equipment_cushions_quantity"
          label="Cushions: Quantity"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_equipment_disposable_filter_eligible"
          label="Disposable Filters: Eligible"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="s3_equipment_disposable_filter_quantity"
          label="Disposable Filters: Quantity"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_equipment_nondisposable_filter_eligible"
          label="Nondisposable Filters: Eligible"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="s3_equipment_nondisposable_filter_quantity"
          label="Nondisposable Filters: Quantity"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="s3_equipment_water_chamber_eligible"
          label="Eligible: Water Chamber"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="s3_equipment_chinstrap_eligible"
          label="Eligible: Chinstrap"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="s3_equipment_standard_tubing_eligible"
          label="Eligible: Standard Tubing"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="s3_equipment_heated_tubing_eligible"
          label="Eligible: Heated Tubing"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="s3_equipment_mask_eligible"
          label="Eligible: Mask"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="s3_equipment_headgear_eligible"
          label="Eligible: Headgear"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={waiting}
            disabled={!enabledSubmit}
          >
            Place Call
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default S3TaskVoiceAgent;
