import { useContext } from "react";
import { Switch, Typography, notification } from "antd";
import { AuthenticatedApi } from "../../utils/AuthenticatedApi";
import { handleError, formatMessage } from "../../utils/utilities";
import ConfigContext from "../../context/ConfigContext";
import { useTheme } from "../../context/ThemeContext";

const { Title } = Typography;
const Settings = (props) => {
  const {
    showExtApiCallbackToggle,
    isExternalApiCallbackEnabled,
    setIsExternalApiCallbackEnabled,
    isExternalApiRead,
    setIsExternalApiRead,
    isMarkEmailsAsRead,
    setIsMarkEmailsAsRead,
  } = props;
  const appConfig = useContext(ConfigContext);
  const { isDarkMode, toggleTheme } = useTheme();

  const toggleExternalApiCallback = async () => {
    try {
      let formData = new FormData();
      formData.append("is_callback_enabled", !isExternalApiCallbackEnabled);
      await AuthenticatedApi.post("/api/update-org-callback", formData);
      notification.success({
        message: formatMessage(appConfig.messages.tasks.api_callback_toggle, {
          status:
            !isExternalApiCallbackEnabled === true ? "enabled" : "disabled",
        }),
      });
      setIsExternalApiCallbackEnabled(!isExternalApiCallbackEnabled);
    } catch (error) {
      console.error(error);
      const errorMessage = handleError(error);
      notification.error({ message: errorMessage });
    }
  };

  const toggleMarkEmailsAsRead = async () => {
    try {
      let formData = new FormData();
      formData.append("mark_emails_as_read", !isMarkEmailsAsRead);
      await AuthenticatedApi.post(
        "/api/update-org-mark-emails-as-read",
        formData
      );
      notification.success({
        message: formatMessage(appConfig.messages.tasks.mark_emails_as_read, {
          status: !isMarkEmailsAsRead === true ? "enabled" : "disabled",
        }),
      });
      setIsMarkEmailsAsRead(!isMarkEmailsAsRead);
    } catch (error) {
      console.error(error);
      const errorMessage = handleError(error);
      notification.error({ message: errorMessage });
    }
  };

  const toggleIsExternalApiRead = async () => {
    try {
      let formData = new FormData();
      formData.append("external_read", !isExternalApiRead);
      await AuthenticatedApi.post("/api/update-org-external-read", formData);
      notification.success({
        message: formatMessage(appConfig.messages.tasks.external_read, {
          status: !isExternalApiRead === true ? "enabled" : "disabled",
        }),
      });
      setIsExternalApiRead(!isExternalApiRead);
    } catch (error) {
      console.error(error);
      const errorMessage = handleError(error);
      notification.error({ message: errorMessage });
    }
  };

  return (
    <div className="api-key-container">
      <Title className="edit-profile-title">Settings</Title>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          paddingBottom: "100px",
        }}
      >
        <div
          style={{
            display: "flex",
            direction: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            paddingBottom: "20px",
          }}
        >
          <h3 style={{ paddingRight: "20px" }}>Change Theme</h3>
          <Switch
            checkedChildren="Dark"
            unCheckedChildren="Light"
            onChange={toggleTheme}
            checked={isDarkMode}
          />
        </div>
        {showExtApiCallbackToggle && (
          <div
            style={{
              display: "flex",
              direction: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              paddingBottom: "20px",
            }}
          >
            <h3 style={{ paddingRight: "20px" }}>
              Enable Export to External API
            </h3>
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              onChange={toggleExternalApiCallback}
              checked={isExternalApiCallbackEnabled}
            />
          </div>
        )}
        <div
          style={{
            display: "flex",
            direction: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            paddingBottom: "20px",
          }}
        >
          <h3 style={{ marginRight: "20px" }}>Enable Read From External API</h3>
          <Switch
            checkedChildren="Enabled"
            unCheckedChildren="Disabled"
            onChange={toggleIsExternalApiRead}
            checked={isExternalApiRead}
          />
        </div>
        <div
          style={{
            display: "flex",
            direction: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            paddingBottom: "100px",
          }}
        >
          <h3 style={{ marginRight: "20px" }}>Mark Email As Read</h3>
          <Switch
            checkedChildren="Enabled"
            unCheckedChildren="Disabled"
            onChange={toggleMarkEmailsAsRead}
            checked={isMarkEmailsAsRead}
          />
        </div>
      </div>
    </div>
  );
};

export default Settings;
