import { useEffect, useState } from "react";
import { Button, notification, Spin } from "antd";
import { UnAuthenticatedApi } from "../utils/MyApi";
import { useNavigate, useLocation } from "react-router-dom";
import { handleError } from "../utils/utilities";

// Set a throttle time (in milliseconds) for resending the email (e.g., 5 minutes)
const RESEND_THROTTLE_TIME = 5 * 60 * 1000; // 5 minutes in milliseconds

function ResendVerificationEmail() {
  const [isLoading, setIsLoading] = useState(false);
  const [canResend, setCanResend] = useState(true);
  const [timeLeft, setTimeLeft] = useState(null); // State to store time left for resend
  const navigate = useNavigate();
  const location = useLocation();

  const showContactOnly = location.state?.showContactOnly || false;

  const resendVerificationEmail = () => {
    const currentTime = new Date().getTime();
    const lastResendTime = localStorage.getItem("lastResendVerificationTime");

    if (lastResendTime && currentTime - lastResendTime < RESEND_THROTTLE_TIME) {
      const timeRemaining =
        RESEND_THROTTLE_TIME - (currentTime - lastResendTime);
      const minutes = Math.floor(timeRemaining / 60000);
      const seconds = Math.floor((timeRemaining % 60000) / 1000);
      notification.error({
        message: `Please wait ${minutes} minutes and ${seconds} seconds before requesting a new verification email.`,
      });
      return;
    }

    setIsLoading(true);
    const tempToken = localStorage.getItem("tempToken");
    UnAuthenticatedApi.post("/api/resend_verification_email", null, {
      headers: {
        Authorization: `Bearer ${tempToken}`,
        UID: "temp",
      },
    })
      .then(() => {
        notification.success({
          message: "Verification email has been resent.",
        });
        localStorage.setItem("lastResendVerificationTime", currentTime);
        setCanResend(false);
        navigate("/sign_in");
      })
      .catch((error) => {
        const errorMessage = handleError(error);
        notification.error({ message: errorMessage });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const tempToken = localStorage.getItem("tempToken");
    if (!tempToken) {
      notification.error({ message: "Token is missing or invalid." });
    }

    // Check if the user can resend based on the lastResendVerificationTime
    const lastResendTime = localStorage.getItem("lastResendVerificationTime");
    if (lastResendTime) {
      const currentTime = new Date().getTime();
      if (currentTime - lastResendTime < RESEND_THROTTLE_TIME) {
        setCanResend(false);

        const timeRemaining =
          RESEND_THROTTLE_TIME - (currentTime - lastResendTime);
        const minutes = Math.floor(timeRemaining / 60000);
        const seconds = Math.floor((timeRemaining % 60000) / 1000);
        setTimeLeft(`${minutes} minutes and ${seconds} seconds`);
      }
    }
  }, []);

  if (showContactOnly) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <h2>Looks like something is not right</h2>
        <p>Check your email inbox for an invitation email.</p>
        <p>
          If you don't have one contact our support at{" "}
          <a href="mailto:info@synthpop.ai">info@synthpop.ai</a>.
        </p>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <h2>Your verification link has expired</h2>
      <p>Please click the button below to request a new verification email.</p>

      <Button
        type="primary"
        onClick={resendVerificationEmail}
        disabled={isLoading || !canResend}
      >
        {isLoading ? <Spin /> : "Resend Verification Email"}
      </Button>

      {!canResend && (
        <p style={{ marginTop: "10px", color: "red" }}>
          You can request a new verification email in {timeLeft}.
        </p>
      )}

      <p style={{ marginTop: "20px" }}>
        If you continue to experience issues, contact our support at{" "}
        <a href="mailto:info@synthpop.ai">info@synthpop.ai</a>.
      </p>
    </div>
  );
}

export default ResendVerificationEmail;
