import { useAuth0 } from "@auth0/auth0-react";
import { Button, notification } from "antd";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import "../style.css";

const LoginPage = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();

  const redirectUri = location.state?.from?.pathname || "/tasks-list";

  // redirect to /clinical-eligibility if already logged in
  if (!isLoading && isAuthenticated) {
    return <Navigate to={redirectUri} replace />;
  }

  const handleSignup = () => {
    const tempToken = localStorage.getItem("tempToken");

    // If tempToken is missing, navigate to the resend verification page
    if (!tempToken) {
      notification.error({
        message: "Something is not right. Please contact support.",
      });
      navigate("/resend_verification", { state: { showContactOnly: true } });
    } else {
      loginWithRedirect({
        authorizationParams: {
          screen_hint: "signup",
        },
        appState: { returnTo: `${window.location.origin}/auth-callback` },
      });
    }
  };

  return (
    <div>
      <Button
        style={{ margin: 30 }}
        onClick={() =>
          loginWithRedirect({
            appState: { returnTo: redirectUri },
          })
        }
        type="primary"
      >
        Log In
      </Button>
      <Button onClick={handleSignup} type="primary">
        Sign up
      </Button>
    </div>
  );
};

export default LoginPage;
