import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, notification, Typography } from "antd";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import "./EditProfilePage.css";
import "../style.css";
import { useAuthRole } from "../context/AuthRoleContext";
import { handleError } from "../utils/utilities";
import AutoLogout from "./AutoLogout";
import LoadingComponent from "./LoadingComponent";

const { Title } = Typography;

const EditProfilePage = () => {
  const navigate = useNavigate();
  const [waiting, setWaiting] = useState(false);
  const { notFound, role, email, name, userId, loaded } = useAuthRole();

  useEffect(() => {
    const checkAuth = async () => {
      if (name && email && userId) {
        AuthenticatedApi.get("/api/user")
          .then((response) => {
            if (response.status === 200) {
              console.log(response.data.subscribed);
            } else {
              console.log(response.data.message);
            }
          })
          .catch((error) => {
            const errorMessage = handleError(error);
            notification.error({ message: errorMessage });
          });
      }
    };

    checkAuth();
  }, [name, email, userId]);

  const navigateToResubmit = () => {
    navigate("/tasks/-1/resubmit");
  };

  const onFinish = (values) => {
    setWaiting(true);
    AuthenticatedApi.put(
      "/api/user",
      {
        user_id: userId,
        new_name: values.name,
        new_email: values.email,
      },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    )
      .then((response) => {
        setWaiting(false);
        if (response.status === 200) {
          notification.success({ message: response.data.message });
          let authState = localStorage.getItem("authState");
          if (authState) {
            authState = JSON.parse(authState);
            authState.name = values.name;
            localStorage.setItem("authState", JSON.stringify(authState));
          }
        } else {
          notification.error({ message: response.data.message });
        }
      })
      .catch((error) => {
        setWaiting(false);
        const errorMessage = handleError(error);
        notification.error({ message: errorMessage });
      });
  };

  return (
    <div>
      {!loaded && <LoadingComponent />}

      {notFound && (
        <div className="loading">
          The email you entered was not found in our database. Please contact
          elad@synthpop.ai to set up an account.
        </div>
      )}

      {!notFound && loaded && (
        <div>
          <div className="edit-profile-container">
            <Title className="edit-profile-title">Account</Title>
            <Form
              layout={"vertical"}
              name="editProfile"
              initialValues={{
                name: name,
                email: email,
                role: role,
              }}
              onFinish={onFinish}
            >
              <Form.Item
                className="edit-profile-text"
                label="Name"
                name="name"
                rules={[{ required: true, message: "Please input your name!" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                className="edit-profile-text"
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input readOnly={true} />
              </Form.Item>

              <Form.Item
                className="edit-profile-text"
                label="Role"
                name="role"
                rules={[{ required: false }]}
              >
                <Input className="edit-profile-title" disabled />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" loading={waiting}>
                  Update Profile
                </Button>
                <Button
                  style={{ marginLeft: "10px" }}
                  onClick={navigateToResubmit}
                >
                  Upload external file
                </Button>
              </Form.Item>
            </Form>
            <AutoLogout />
          </div>
        </div>
      )}
    </div>
  );
};

export default EditProfilePage;
