import { useEffect, useState } from "react";
import {
  fetchUtils,
  Admin,
  Resource,
  ListGuesser,
  List,
  Datagrid,
  TextField,
  ReferenceField,
  ShowGuesser,
  defaultTheme,
  Layout,
  Menu,
  Toolbar,
  SaveButton,
  Button,
  useRedirect,
  useRecordContext,
  useNotify,
  Show,
  SimpleShowLayout,
  FunctionField,
  ArrayField,
  DateField,
  EditGuesser,
} from "react-admin";
import {
  HomeOutlined,
  UsergroupDeleteOutlined,
  ProfileOutlined,
  RocketOutlined,
  FireOutlined,
  LoginOutlined,
  BankOutlined,
  MedicineBoxOutlined,
  FileProtectOutlined,
} from "@ant-design/icons";
import simpleRestProvider from "ra-data-simple-rest";

import { useAuth0 } from "@auth0/auth0-react";
import { useAuthRole } from "../context/AuthRoleContext";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import { handleError } from "../utils/utilities";
import { useTheme } from "./../context/ThemeContext";
import LoadingComponent from "./LoadingComponent";
import {
  UserList,
  UserEdit,
  UserShow,
  UserAccountFilter,
} from "./admin/UserAccount";
import {
  ListTaskType,
  CreateTaskType,
  EditTaskType,
  ShowTaskType,
} from "./admin/TaskType";

import { PromptList, PromptShow } from "./admin/Prompt";
import { CreatePcode, EditPcode } from "./admin/Pcode";
import { CreatePolicy, ListPolicy, EditPolicy } from "./admin/Policy";
import { ListCoverage, CreateCoverage, EditCoverage } from "./admin/Coverage";
import { CreateSpecialty, SpecialtyList } from "./admin/Specialty";
import { CreatePayor, PayorList } from "./admin/Payor";
import {
  CreatePolicyProcedureCode,
  EditPolicyProcedureCode,
} from "./admin/PolicyProcedureCode";
import {
  CreateOrganization,
  EditOrganization,
  OrganizationFilter,
} from "./admin/Organization";
import { Typography, Tooltip } from "@mui/material";

const MyMenu = () => {
  const navigateToHome = () => {
    window.location.href = window.location.origin + "/";
  };

  return (
    <Menu>
      <Menu.Item
        onClick={navigateToHome}
        to="#"
        primaryText="Home"
        leftIcon={<HomeOutlined />}
      />
      <Menu.Item
        to="/admin/organizations"
        primaryText="Organizations"
        leftIcon={<ProfileOutlined />}
      />
      <Menu.Item
        to="/admin/user_accounts"
        primaryText="Accounts"
        leftIcon={<UsergroupDeleteOutlined />}
      />
      <Menu.Item
        to="/admin/prompts"
        primaryText="Prompts"
        leftIcon={<RocketOutlined />}
      />
      <Menu.Item
        to="/admin/incidents"
        primaryText="Incidents"
        leftIcon={<FireOutlined />}
      />
      <Menu.Item
        to="/admin/user_logins"
        primaryText="User Login"
        leftIcon={<LoginOutlined />}
      />
      <Menu.Item
        to="/admin/payors"
        primaryText="Payor"
        leftIcon={<BankOutlined />}
      />
      <Menu.Item
        to="/admin/specialties"
        primaryText="Specialty"
        leftIcon={<MedicineBoxOutlined />}
      />
      <Menu.Item
        to="/admin/coverages"
        primaryText="Coverage"
        leftIcon={<FileProtectOutlined />}
      />
      <Menu.Item
        to="/admin/policies"
        primaryText="Policy"
        leftIcon={<FileProtectOutlined />}
      />
      <Menu.Item
        to="/admin/procedure_codes"
        primaryText="Procedure Code"
        leftIcon={<FileProtectOutlined />}
      />
      <Menu.Item
        to="/admin/policy_procedures"
        primaryText="Policy Procedure"
        leftIcon={<FileProtectOutlined />}
      />
      <Menu.Item
        to="/admin/waitlists"
        primaryText="Waitlist"
        leftIcon={<FileProtectOutlined />}
      />
      <Menu.Item
        to="/admin/task_types"
        primaryText="Task Types"
        leftIcon={<FileProtectOutlined />}
      />
    </Menu>
  );
};
const LimitedMenu = () => {
  const navigateToHome = () => {
    window.location.href = window.location.origin + "/";
  };

  return (
    <Menu>
      <Menu.Item
        onClick={navigateToHome}
        to="#"
        primaryText="Home"
        leftIcon={<HomeOutlined />}
      />
      <Menu.Item
        to="/admin/organizations"
        primaryText="Organizations"
        leftIcon={<ProfileOutlined />}
      />
      <Menu.Item
        to="/admin/user_accounts"
        primaryText="Accounts"
        leftIcon={<UsergroupDeleteOutlined />}
      />
      <Menu.Item
        to="/admin/organization_api_keys"
        primaryText="Api Keys"
        leftIcon={<UsergroupDeleteOutlined />}
      />
    </Menu>
  );
};

const MyLayout = (props) => <Layout {...props} menu={MyMenu} />;

const LimitedLayout = (props) => (
  <Layout {...props} title="Limited Access" menu={LimitedMenu} />
);

export const CustomEditToolbar = (props) => {
  const redirect = useRedirect();
  return (
    <Toolbar {...props}>
      <SaveButton />
      <Button
        label="Cancel"
        onClick={() => redirect("/admin/prompts")} // Adjust the path to your needs. This redirects to the list view.
      />
    </Toolbar>
  );
};

// eslint-disable-next-line react/prop-types
const JsonField = ({ record, source }) => {
  return (
    <FunctionField
      record={record}
      source={source}
      render={(record) => JSON.stringify(record[source], null, 2)}
    />
  );
};

const IncidentShow = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="context" />
        <TextField source="method" />
        <TextField source="url" />
        <TextField source="status_code" />
        <TextField source="message" />
        <JsonField source="metadata" />
        <TextField source="backtrace" />
        <TextField source="created_at" />
      </SimpleShowLayout>
    </Show>
  );
};

const UserLoginsShow = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="user" />
        <ArrayField source="user_logins_serializable">
          <Datagrid>
            <TextField source="id" />
            <TextField source="browser_header" label="Browser Header" />
            <DateField source="last_login" label="Last Login" />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};

const EllipsisTextField = ({ source, maxLength = 40 }) => {
  const record = useRecordContext();
  const data = record[source];

  console.log("data: ", data);

  if (!data || !Array.isArray(data)) return null;

  // Convert the array to a single string, separated by commas
  const text = data.join(", ");

  // If the text is longer than maxLength, apply ellipsis
  const displayText =
    text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

  console.log("displayText: ", displayText);

  return (
    <Tooltip title={text}>
      <Typography variant="body2" noWrap>
        {displayText}
      </Typography>
    </Tooltip>
  );
};

const AdminPage = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { isSuperAdmin, isGroupAdmin, setTriggerRefresh } = useAuthRole();
  const [dataProvider, setDataProvider] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  const ListOrganization = (props) => {
    return (
      <List {...props}>
        <Datagrid rowClick="edit">
          <TextField source="id" />
          <TextField source="name" />
          <EllipsisTextField source="fax_numbers" />
          <EllipsisTextField source="dedicated_fax_numbers" />
          <TextField source="owner" />
          <SwitchToThisOrgButton label="Action" />
          <ReferenceField
            source="parent_org_id"
            reference="organizations"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
        </Datagrid>
      </List>
    );
  };

  const SwitchToThisOrgButton = () => {
    const record = useRecordContext();
    const notify = useNotify();

    const handleClick = (event) => {
      event.stopPropagation();
      console.log("Button clicked for record:", record);

      AuthenticatedApi.post(`/api/switch_org`, { org_id: record.id })
        .then((response) => {
          console.log(response.data);
          console.log("Switched to org:", record.id);
          setTriggerRefresh(true);
          notify("Switched to org successfully.", "success");
        })
        .catch((error) => {
          console.error("Error switching org: ", error);
          const errorMessage = handleError(error); // Assuming handleError gives a string message
          notify(errorMessage, "error");
        });
    };

    return <Button onClick={handleClick}>Switch to this Organization</Button>;
  };

  useEffect(() => {
    const fetchDataProvider = async () => {
      console.log("Access Token: ", user);
      const token = await getAccessTokenSilently();
      setAccessToken(token);
      const fetchJson = (url, options = {}) => {
        if (!options.headers) {
          options.headers = new Headers({ Accept: "application/json" });
        }
        if (user) {
          options.headers.set("UID", user.email);
        }
        options.headers.set(
          "Authorization",
          `Bearer ${accessToken !== null ? accessToken : token}`
        );
        return fetchUtils.fetchJson(url, options);
      };
      const dataProvider = simpleRestProvider(
        `${window.location.origin}/api`,
        fetchJson
      );
      const myDataProvider = {
        ...dataProvider,
        create: async (resource, params) => {
          if (resource !== "policies" || !params.data.file) {
            return dataProvider.create(resource, params);
          }

          // Use FormData for file upload
          let formData = new FormData();
          formData.append("name", params.data.name);
          formData.append("content", params.data.content || "");
          formData.append("payor_id", params.data.payor_id);
          formData.append("specialty_id", params.data.specialty_id);
          formData.append("coverage_id", params.data.coverage_id);
          // Format the validity_date to an ISO string if it exists and is not already a string
          if (params.data.validity_date) {
            let validityDate = params.data.validity_date;
            if (
              !(
                typeof validityDate === "string" ||
                validityDate instanceof String
              )
            ) {
              validityDate = new Date(validityDate).toISOString();
            }
            formData.append("validity_date", validityDate);
          }

          // Append the file if present
          if (params.data.file && params.data.file.rawFile) {
            formData.append("file", params.data.file.rawFile);
          }

          // Make the request with FormData
          const response = await AuthenticatedApi.post(
            "/api/policies",
            formData
          );

          return response;
        },
        update: async (resource, params) => {
          if (resource !== "policies" || !params.data.file) {
            return dataProvider.update(resource, params);
          }
          let formData = new FormData();
          formData.append("name", params.data.name);
          formData.append("content", params.data.content || "");
          formData.append("payor_id", params.data.payor_id);
          formData.append("specialty_id", params.data.specialty_id);
          formData.append("coverage_id", params.data.coverage_id);
          // Format the validity_date to an ISO string if it exists and is not already a string
          if (params.data.validity_date) {
            let validityDate = params.data.validity_date;
            if (
              !(
                typeof validityDate === "string" ||
                validityDate instanceof String
              )
            ) {
              validityDate = new Date(validityDate).toISOString();
            }
            formData.append("validity_date", validityDate);
          }
          if (params.data.file && params.data.file.rawFile) {
            formData.append("file", params.data.file.rawFile);
          }
          const response = AuthenticatedApi.put(
            `/api/policies/${params.id}`,
            formData
          );
          return response;
        },
      };

      setDataProvider(() => myDataProvider);
    };

    fetchDataProvider();
  }, [accessToken, getAccessTokenSilently, user]);

  const { isDarkMode } = useTheme();
  const myTheme = {
    ...defaultTheme,
    palette: {
      mode: isDarkMode ? "dark" : "light",
    },
  };

  if (!dataProvider) {
    return <LoadingComponent />;
  }

  return (
    <div>
      {isSuperAdmin && (
        <Admin
          dataProvider={dataProvider}
          basename="/admin"
          theme={myTheme}
          layout={MyLayout}
        >
          <Resource
            name="organizations"
            basePath="/admin/organizations"
            options={{ label: "Organizations", menu: "admin/organizations" }}
            list={(props) => (
              <ListOrganization {...props} filters={<OrganizationFilter />} />
            )}
            edit={EditOrganization}
            show={ShowGuesser}
            create={CreateOrganization}
          />
          <Resource
            name="user_accounts"
            basePath="/admin/user_accounts"
            options={{ label: "Accounts", menu: "admin/user_accounts" }}
            list={(props) => (
              <UserList {...props} filters={<UserAccountFilter />} />
            )}
            edit={UserEdit}
            show={UserShow}
          />
          <Resource
            name="prompts"
            basePath="/admin/prompts"
            options={{ label: "Prompts", menu: "admin/prompts" }}
            list={PromptList}
            show={PromptShow}
          />
          <Resource
            name="incidents"
            basePath="/admin/incidents"
            options={{ label: "Incidents", menu: "admin/incidents" }}
            list={ListGuesser}
            show={IncidentShow}
          />
          <Resource
            name="user_logins"
            basePath="/admin/user_logins"
            options={{ label: "User Logins", menu: "admin/user_logins" }}
            list={ListGuesser}
            show={UserLoginsShow}
          />
          <Resource
            name="payors"
            basePath="/admin/payors"
            options={{ label: "Payors", menu: "admin/payors" }}
            list={PayorList}
            create={CreatePayor}
            show={ShowGuesser}
            edit={EditGuesser}
          />
          <Resource
            name="specialties"
            basePath="/admin/specialties"
            options={{ label: "Specialties", menu: "admin/specialties" }}
            list={SpecialtyList}
            create={CreateSpecialty}
            show={ShowGuesser}
            edit={EditGuesser}
          />
          <Resource
            name="coverages"
            basePath="/admin/coverages"
            options={{ label: "coverages", menu: "admin/coverages" }}
            list={ListCoverage}
            create={CreateCoverage}
            show={ShowGuesser}
            edit={EditCoverage}
          />
          <Resource
            name="policies"
            basePath="/admin/policies"
            options={{ label: "Policies", menu: "admin/policies" }}
            list={ListPolicy}
            create={CreatePolicy}
            show={ShowGuesser}
            edit={EditPolicy}
          />
          <Resource
            name="policy_procedures"
            basePath="/admin/policy_procedures"
            options={{
              label: "Policy Procedure Code",
              menu: "admin/policy_procedures",
            }}
            list={ListGuesser}
            create={CreatePolicyProcedureCode}
            show={ShowGuesser}
            edit={EditPolicyProcedureCode}
          />
          <Resource
            name="procedure_codes"
            basePath="/admin/procedure_codes"
            options={{ label: "Procedure Code", menu: "admin/procedure_codes" }}
            list={ListGuesser}
            create={CreatePcode}
            show={ShowGuesser}
            edit={EditPcode}
          />
          <Resource
            name="waitlists"
            basePath="/admin/waitlists"
            options={{ label: "Waitlist", menu: "admin/waitlists" }}
            list={ListGuesser}
          />
          <Resource
            name="task_types"
            basePath="/admin/task_types"
            options={{ label: "Task Types", menu: "admin/task_types" }}
            list={ListTaskType}
            show={ShowTaskType}
            edit={EditTaskType}
            create={CreateTaskType}
          />
        </Admin>
      )}
      {!isSuperAdmin && !isGroupAdmin && (
        <div style={{ textAlign: "center" }}>
          <h1>You are not authorized to view this page.</h1>
        </div>
      )}
      {!isSuperAdmin && isGroupAdmin && (
        <>
          <Admin
            dataProvider={dataProvider}
            basename="/admin"
            theme={myTheme}
            layout={LimitedLayout}
          >
            <Resource
              name="organizations"
              basePath="/admin/organizations"
              options={{ label: "Organizations", menu: "admin/organizations" }}
              list={(props) => (
                <ListOrganization {...props} filters={<OrganizationFilter />} />
              )}
              edit={EditOrganization}
              show={ShowGuesser}
              create={CreateOrganization}
            />
            <Resource
              name="user_accounts"
              basePath="/admin/user_accounts"
              options={{ label: "Accounts", menu: "admin/user_accounts" }}
              list={(props) => (
                <UserList {...props} filters={<UserAccountFilter />} />
              )}
              edit={UserEdit}
              show={UserShow}
            />
          </Admin>
        </>
      )}
    </div>
  );
};

export default AdminPage;
