import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingComponent from "./LoadingComponent";

const SignUpRedirect = () => {
  const { orgName } = useParams();
  const { loginWithRedirect } = useAuth0();
  const email = localStorage.getItem("tempEmail");

  useEffect(() => {
    loginWithRedirect({
      authorizationParams: {
        screen_hint: "signup",
        login_hint: email,
      },
    });
    localStorage.removeItem("tempEmail");
  }, [orgName, loginWithRedirect]);

  return <LoadingComponent />;
};

export default SignUpRedirect;
