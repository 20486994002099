import {
  List,
  Datagrid,
  TextField,
  ExportButton,
  TopToolbar,
  Show,
  SimpleShowLayout,
  FunctionField,
} from "react-admin";
import EllipsisTextField from "./../EllipsisTextField";
import { ImportButton } from "react-admin-import-csv";

const transformImportedData = (action, data) => {
  console.log("Transforming Data", action, data);
  return data;
};

const MyImportButton = (props) => (
  <ImportButton
    {...props}
    parseConfig={{ header: true, dynamicTyping: true, skipEmptyLines: true }}
    preCommitCallback={transformImportedData}
  />
);

const ListActions = (props) => {
  // eslint-disable-next-line react/prop-types
  const { className } = props;
  return (
    <TopToolbar className={className}>
      <ExportButton />
      <MyImportButton {...props} />
    </TopToolbar>
  );
};

// List Prompt Section
export const PromptList = (props) => (
  <List {...props} actions={<ListActions />}>
    <Datagrid rowClick="show">
      <TextField source="short_name" />
      <TextField source="task_type" />
      <TextField source="timestamp" />
      <EllipsisTextField source="context" />
      <EllipsisTextField source="prompt" />
    </Datagrid>
  </List>
);

export const PromptShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="short_name" />
      <TextField source="task_type" />
      <TextField source="timestamp" />
      <TextField source="context" />
      <FunctionField
        label="Prompt"
        render={(record) => (
          <p style={{ whiteSpace: "pre-wrap" }}>{record.prompt}</p>
        )}
      />
    </SimpleShowLayout>
  </Show>
);
