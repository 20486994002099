import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  ReferenceField,
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  Button,
  useRecordContext,
  useNotify,
  Show,
  SimpleShowLayout,
  Filter,
} from "react-admin";

import { AuthenticatedApi } from "../../utils/AuthenticatedApi";
import { handleError } from "../../utils/utilities";
import ResendVerificationEmailButton from "./ResendVerificationEmailButton";

const SendLinkButton = () => {
  const record = useRecordContext();
  const notify = useNotify();

  const handleButtonClick = (event) => {
    event.stopPropagation();
    console.log("Button clicked for record:", record);

    const formData = new FormData();
    formData.append("email", record.email);

    AuthenticatedApi.post(`/api/tasks/-1/send_link`, formData, {
      params: { internal_request: true },
    })
      .then((response) => {
        console.log(response.data);
        console.log("Sending email to:", record.email);
        notify("Email sent successfully.", "success");
      })
      .catch((error) => {
        console.error("Error generating letter: ", error);
        const errorMessage = handleError(error); // Assuming handleError gives a string message
        notify(errorMessage, "error");
      });
  };

  return (
    <Button onClick={handleButtonClick}>Send External File Upload Link</Button>
  );
};

const ToggleInActivateButton = () => {
  const record = useRecordContext();
  const notify = useNotify();
  let isActivated = record.role !== "inactive";

  const handleButtonClick = (event) => {
    event.stopPropagation();
    console.log("Button clicked for record:", record);

    AuthenticatedApi.put(`/api/user_accounts/${record.id}/toggle_inactivate`)
      .then((response) => {
        console.log(response.data);
        console.log("Toggled inactivate for:", record.id);
        notify("Toggled inactivate successfully.", "success");
      })
      .catch((error) => {
        console.error("Error toggling inactivate: ", error);
        const errorMessage = handleError(error); // Assuming handleError gives a string message
        notify(errorMessage, "error");
      });
  };

  return (
    <Button onClick={handleButtonClick}>
      {isActivated ? "Inactivate" : "Activate"}
    </Button>
  );
};

export const UserAccountFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by Name" source="name" autoFocus />
    <TextInput label="Search by Email" source="email" autoFocus />
  </Filter>
);

// List User
export const UserList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="org_id" />
      <TextField source="role" />
      <TextField source="email" />
      <BooleanField source="subscribed" />
      <TextField source="subscription_end_timestamp" />
      <ToggleInActivateButton label="Activate/In-Activate" />
      <SendLinkButton label="Action" />
      <ResendVerificationEmailButton label="Resend Verification Email" />
    </Datagrid>
  </List>
);

export const UserShow = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceField source="org_id" reference="organizations" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="role" />
        <TextField source="email" />
        <BooleanField source="subscribed" />
        <TextField source="subscription_end_timestamp" />
      </SimpleShowLayout>
    </Show>
  );
};

export const UserEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="id" inputProps={{ readOnly: true }} />
        <TextInput source="name" />
        <ReferenceInput
          source="org_id"
          reference="organizations"
          allowEmpty
          inputProps={{ readOnly: true }}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="role" />
        <TextInput source="email" inputProps={{ readOnly: true }} />
        <BooleanInput source="subscribed" inputProps={{ readOnly: true }} />
      </SimpleForm>
    </Edit>
  );
};
